<template>
  <div>
    <!--------------------面包屑导航-------------------->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>学校首页</el-breadcrumb-item>
      <el-breadcrumb-item>首页图片</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20"><!--栅格中每个组件的间距，20px-->
        <el-col :span="6"><!--占的比重，一共24-->
          <el-button type="primary" @click="showAddDialog()">添加图片</el-button>
<!--          <el-button type="primary" @click="showAddDialog(2)">添加移动端图片</el-button>-->
        </el-col>
      </el-row>
      <!--------------------表格--------------------->
      <el-table :data="tableData" stripe border><!--stripe带斑马纹的表格 border边框-->
        <el-table-column type="index" label="序号" width="60px">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 + queryInfo.pageSize * (queryInfo.currentPage - 1) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="client" label="类型">
          <template slot-scope="scope">
            <span v-if="scope.row.client === '1'">
              PC端
            </span>
            <span v-if="scope.row.client === '2'">
              移动端
            </span>
            <span v-if="scope.row.client === '3'">
             课程体系
            </span>
            <span v-if="scope.row.client === '4'">
              办学成果
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="url" label="照片">
          <template slot-scope="scope">
            <div style="height: 50px;">
              <img alt="" :src="scope.row.url" style="height: 100%"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="显示顺序"></el-table-column>
        <el-table-column prop="sourceUrl" label="跳转路径"></el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <!--预览-->
            <el-button type="primary" size="mini" @click="showEditDialog(scope.row)">编辑</el-button>
            <!--预览-->
            <el-button type="primary" size="mini" @click="showPreviewDialog(scope.row)">预览</el-button>
            <!--删除-->
            <el-button type="danger" size="mini" @click="removeItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--------------------添加用户对话框--------------------->
      <el-dialog title="上传照片" :visible.sync="addDialogVisible"
                 width="45%" @close="closePreviewDialog()">
        <!--内容主体信息-->
        <!-- 上传图片,:action要上传的url,handlePreview预览图片方法，handleRemove移除图片方法,:headers设置请求头  -->
        <div class="search">
          <div class="label">类型：</div>
          <el-select v-model="typeIndex" filterable placeholder="请选择">
            <el-option v-for="(item, index) in typeArr" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="search">
          <span class="label">照片：</span>
          <el-upload :on-preview="handlePreview" :class="{disabled:uploadDisabled}" :length="imagelist"
                     :before-upload="beforeAvatarUpload"  action="#"
                     :http-request="httpRequest"
                     list-type="picture-card" :headers="headersObj" :on-success="handleSuccess" ref="myUpload">
            <i class="el-icon-plus add-icon"></i>
            <div v-if="typeIndex === 1">
              <span class="tip" style="top: 23px;">限制1280px*640px大小</span>
              <span class="tip" style="top: 46px;">支持png、jpg、jpeg格式图片</span>
            </div>
            <div v-if="typeIndex === 2">
              <span class="tip" style="top: 23px;">限制750px*400px大小</span>
              <span class="tip" style="top: 46px;">支持png、jpg、jpeg格式图片</span>
            </div>
            <div v-if="typeIndex === 3 || typeIndex === 4">
              <span class="tip" style="top: 23px;">限制400px*240px大小</span>
              <span class="tip" style="top: 46px;">支持png、jpg、jpeg格式图片</span>
            </div>
          </el-upload>
        </div>
          <div class="search" v-if="typeIndex === 3">
            <span class="label">显示顺序：</span>
            <el-select v-model="lessonIndex" filterable placeholder="请选择">
              <el-option v-for="(item, index) in lessonArr" :key="index" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
        <div class="search" v-else>
          <span class="label">显示顺序：</span>
          <el-select  v-model="index" filterable placeholder="请选择">
            <el-option v-for="(item, index) in webArr" :key="index" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="flex-warp mg15 search" v-if="typeIndex === 1 || typeIndex === 2">
          <span class="label">跳转路径：</span>
          <el-input  v-model="sourceUrl" placeholder="请输入" style="flex:1"></el-input>
        </div>
        <!--底部区域-->
        <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="uploadHttps()" :loading="loading">上 传</el-button>
              </span>
      </el-dialog>
      <!-------------图片预览对话框----------------->
      <el-dialog title="图片预览" :visible.sync="previewPicVisible"
                 width="70%">
<!--        <div style="width: 100%;height: 400px;">
          <img :src="previewPath" style="width: 100%;" >
        </div>-->
        <!--图片宽高都小于容器时垂直、水平居中
        图片宽高都大于容器时保持宽高比将width或height充满容器-->
        <div style="width:100%;height:400px;display: table-cell;vertical-align: middle;text-align: center;">
          <img :src="previewPath" alt="" style="max-width: 100%;max-height: 100%;display: block;margin: 0 auto;"/>
        </div>
      </el-dialog>
      <!-------------编辑对话框----------------->
      <el-dialog title="编辑" :visible.sync="editDialog" @close="closeEditDialog()"
                 width="45%">
        <div v-if="type === '3'">
          <span>显示顺序：</span>
          <el-select  v-model="lessonIndex" filterable placeholder="请选择">
            <el-option v-for="(item, index) in lessonArr" :key="index" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div v-else>
          <span>显示顺序：</span>
          <el-select v-model="index" filterable placeholder="请选择">
            <el-option v-for="(item, index) in webArr" :key="index" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="flex-warp mg15"  v-if="type === '1' || type === '2'">
          <span>跳转路径：</span>
          <el-input  v-model="sourceUrl" placeholder="请输入" style="flex:1"></el-input>
        </div>
        <!--底部区域-->
        <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">取 消</el-button>
                <el-button type="primary" @click="submitEdit()" :loading="loading">确 定</el-button>
              </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Photo",
  data() {
    return {
      loading: false,
      addDialogVisible: false,
      queryInfo: {
        currentPage: 1,//当前页码
        pageSize: 10,//每页显示多少条
      },
      totalCount: 0,
      tableData: [],
      urlArr: [],//图片路径数组
      // 图片路径数组
      pics: [],
      //uploadURL: 'api/banner/add',
      // 上传图片的请求头
      headersObj: {
        Authorization: window.localStorage.getItem('token')
      },
      // 图片预览路径
      previewPath: '',
      // 控制图片预览对话框的显示与隐藏
      previewPicVisible: false,
      filenames: '',//上传的文件名
      httpData: {},//http数据转存
      client: '',//客户端类型
      fileArr: [],
      index: 1,
      webArr: [1, 2, 3, 4],//排序值
      lessonArr: [1, 2, 3],//课程体系排序值
      lessonIndex: 1,
      //mobileArr: [1, 2, 3, 4],//排序值
      imagelist: [],//添加对话框里图片数量，控制只添加1张
      editDialog: false,
      photoInfo: {}, //打开编辑对话框的信息
      sourceUrl: '',//跳转路径
      typeArr: [
        {id: 1, name: 'PC端'},
        {id: 2, name: '移动端'},
        {id: 3, name: '课程体系'},
        {id: 4, name: '办学成果'},
      ],
      typeIndex: 1,
      type: ''
    }
  },
  computed: {
    uploadDisabled:function() {
      return this.imagelist.length >0
    },
  },
  mounted() {
    this.getList();

  },
  methods: {
    //打开编辑对话框
    showEditDialog(rows) {
      this.editDialog = true;
      this.photoInfo = rows
      this.index = rows.sort
      this.sourceUrl = rows.sourceUrl
      this.type = rows.client
    },
    //关闭编辑对话框
    closeEditDialog() {
      this.index = 1
      this.sourceUrl = ''
      this.photoInfo = {}
    },
    async submitEdit() {
      let index = 1;
      if(this.type === '1' || this.type === '2') {
        index = this.index;
      } else {
        index = this.lessonIndex;
      }
      let editForm = {
        id: this.photoInfo.id,
        sort: index,
        sourceUrl: this.sourceUrl
      }
      this.loading = true;
      const { data: res } = await this.$http.put('/banner/update', editForm)
      this.loading = false;
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        this.editDialog = false // 关闭对话框
        await this.getList() // 刷新列表
        this.$message.success(res.message)
      }
    },
    //打开添加对话框
    showAddDialog(client) {
      this.addDialogVisible = true
      this.client = client
    },
    //上传操作
    async uploadHttps(client) {
      if(JSON.stringify(this.httpData) === "{}") {
        return this.$message.error("请选择要上传的图片")
      }
      this.loading = true;
      // 先创建一个FormData 对象
      let formData = new FormData();
      // 将请求参数一个一个append进去
      let index = 1;
      if(this.typeIndex === 1 || this.typeIndex === 2) {
        index = this.index;
      } else {
        index = this.lessonIndex;
      }
      formData.append('file',this.httpData.file);
      formData.append('client', this.typeIndex);//图片类型 1PC 2移动端
      formData.append('sort', index);//排序值
      formData.append('sourceUrl', this.sourceUrl);//跳转路径
      let {data: res} = await this.$http({
        url: 'banner/add',
        data: formData,
        headers: {
          'Content-Type': "multipart/form-data; charset=UTF-8",
        },
        method:'post'
      });
      if (res.code === 200) {
        this.loading = false;
        this.addDialogVisible = false //关闭弹窗
        this.$message.success('上传成功！')
      } else {
        this.loading = false;
        this.$message.error(res.message)
      }
    },
    httpRequest(http) {
      this.filenames = http.file.name;
      this.httpData = http;
      this.imagelist.push(http)
    },
    beforeAvatarUpload(file) {
      let maxSize = 3 * 1024 * 1024;
      if(file.size > maxSize) {
        this.$message.error('图片不允许超过3M！');
        return false;
      } else {
        let type = file.name.substring(file.name.lastIndexOf('.') + 1);
        if (type === 'png' || type === 'jpg' || type === 'jpeg') {

        } else {
          this.$message.error('图片格式不正确！');
          return false;
        }
      }
    },
    closePreviewDialog() {
      this.$refs.myUpload.clearFiles()
      //this.fileArr = []
      this.httpData = {}
      this.filenames = ''
      this.imagelist = []
      this.index = 1
      this.sourceUrl = ''
      this.getList()
    },
    //列表图片预览
    showPreviewDialog(row) {
      this.previewPath = row.url
      this.previewPicVisible = true
    },
    // 图片预览事件
    handlePreview (file) {
      this.previewPath = file.response.result
      this.previewPicVisible = true
    },

    // 图片移除事件
    async handleRemove (file) {
    },
    // 图片上传成功处理方法
    handleSuccess (response) {

    },
    async getList() {
      const { data: res } = await this.$http.get('/banner/list', {params: this.queryInfo})
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        this.tableData = res.result
      }
    },
    // 监听每页显示多少条的改变
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getList()
    },
    // 监听选择第了几页
    handleCurrentChange (newPage) {
      this.queryInfo.currentPage = newPage
      this.getList()
    },
    //删除
    removeItem(rows) {
      this.$confirm('此操作将永久删除该条数据', '确认要删除吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
        center: true,
        showClose: false,
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            let {data: res} = await this.$http({
              url: '/banner/delete',
              params: {id: rows.id},
              headers:{
                'Content-Type':"application/x-www-form-urlencoded",
              },
              method:'delete'
            });
            if (res.code === 200) {
              done();
              instance.confirmButtonLoading = false;
              this.$message.success(res.message)
              await this.getList()
            } else {
              this.$message.error(res.message)
              instance.confirmButtonLoading = false;
              done();
            }
          } else {
            instance.confirmButtonLoading = false;
            done();
          }
        }
      }).catch(() => {
        this.$message.info('已取消');
      });
    },
  }
}
</script>

<style scoped>
.flex-warp{display:flex;align-items:center;}
.mg15 {margin-top: 15px;}
.search {
  margin-top: 20px;
  display: flex;
}
.search .label {
  width: 80px;
  text-align: right;
  line-height: 40px;
}
</style>
<style>
.el-upload {
  width: 260px;
  height: 168px;
  position: relative;
}
.tip{
  font-size: 13px;
  position: absolute;
  float: left;
  left: 23%;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 260px;
  height: 168px;
}
.add-icon {
  position: absolute;float: left; left: 45%;top: 45px;
}
.el-upload-list--picture-card .el-upload-list__item-actions:hover {
  opacity: 0;
}

.disabled .el-upload--picture-card {
  display: none;
}
.el-upload-list--picture-card .el-upload-list__item{
  border: none;
}
</style>
